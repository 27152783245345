import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Drawer, Fab, Grid, IconButton } from '@material-ui/core';
import { IRoute, RoutesDictionary, RoutesEnum } from '../../models/routes';
import { getHistory } from '../../utils/history.util';

const useStyles = makeStyles(() => ({
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#1a1a1a',
  },
  toolbarItem: {
    width: '20%',
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: 'pink',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  iconWrapper: {
    textAlign: 'center',
  },
}));

export const NavBarBottom = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();

  const getIconButton = (route: IRoute) => {
    return (
      <IconButton
        classes={{ root: classes.toolbarItem, label: classes.iconButtonLabel }}
        key={route.name}
        onClick={() => getHistory().push(route.path)}
      >
        <FontAwesomeIcon icon={['fad', route.faIcon]} />
        <span className={classes.label}>{route.name}</span>
      </IconButton>
    );
  };

  const getButton = (route: IRoute) => {
    return (
      <Grid key={route.name} item xs={4}>
        <div className={classes.iconWrapper}>
          <IconButton classes={{ label: classes.iconButtonLabel }} onClick={() => getHistory().push(route.path)}>
            <FontAwesomeIcon icon={['fad', route.faIcon]} />
            {route.name}
          </IconButton>
        </div>
      </Grid>
    );
  };

  const routes = [
    RoutesDictionary[RoutesEnum.Cashier],
    RoutesDictionary[RoutesEnum.Sportsbook],
    RoutesDictionary[RoutesEnum.LiveSports],
    RoutesDictionary[RoutesEnum.Casino],
  ];

  const drawerRoutes = [
    RoutesDictionary[RoutesEnum.Sportsbook],
    RoutesDictionary[RoutesEnum.LiveSports],
    RoutesDictionary[RoutesEnum.Casino],
    RoutesDictionary[RoutesEnum.Horses],
    RoutesDictionary[RoutesEnum.Promotions],
    RoutesDictionary[RoutesEnum.RAF],
    RoutesDictionary[RoutesEnum.Rules],
  ];

  return (
    <AppBar position='fixed' color='primary' className={classes.appBar}>
      <Toolbar classes={{ root: classes.toolbar }}>
        {routes.map((route: IRoute) => getIconButton(route))}
        <Fab color='secondary' aria-label='add' className={classes.fabButton} onClick={() => setOpenDrawer(true)}>
          <FontAwesomeIcon icon={['fad', 'bars']} />
        </Fab>
      </Toolbar>
      <Drawer anchor='bottom' open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Grid container spacing={3}>
          {drawerRoutes.map((route: IRoute) => getButton(route))}
        </Grid>
      </Drawer>
    </AppBar>
  );
};
