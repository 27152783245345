import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

export const CashierPage = () => {
  const classes = useStyles();

  return <div className={clsx(classes.root)}>Cashier</div>;
};
