import Axios from 'axios';
import { main } from './app';

const APP_ID = 'desking';

export function loadAppSettings(): Promise<void> {
  return Axios.get('app.settings.dev.json')
    .then(response => {
      const appSettings = response.data;
      main(APP_ID, Object.freeze(appSettings));
    })
    .catch();
}

loadAppSettings();
