import { connect } from 'react-redux';
// import { PromotionsPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { PromotionsPage } from './promotions.component';

// function mapStateToProps(state: any): ExternalProps {
//   return {
//     landingPages: landingPagesSelector(state),
//   };
// }

// const mapDispatchToProps: ExternalActionProps = {
//   getLandingPagesAction,
// };

export const PromotionsPageContainer = connect(null, null)(PromotionsPage);
