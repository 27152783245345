import { connect } from 'react-redux';
// import { RulesPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { RulesPage } from './rules.component';

// function mapStateToProps(state: any): ExternalProps {
//   return {
//     landingPages: landingPagesSelector(state),
//   };
// }

// const mapDispatchToProps: ExternalActionProps = {
//   getLandingPagesAction,
// };

export const RulesPageContainer = connect(null, null)(RulesPage);
