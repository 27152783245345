import { connect } from 'react-redux';
// import { CasinoPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { CasinoPage } from './casino.component';

// function mapStateToProps(state: any): ExternalProps {
//   return {
//     landingPages: landingPagesSelector(state),
//   };
// }

// const mapDispatchToProps: ExternalActionProps = {
//   getLandingPagesAction,
// };

export const CasinoPageContainer = connect(null, null)(CasinoPage);
