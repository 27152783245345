import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import clsx from 'clsx';
import { Hidden, makeStyles } from '@material-ui/core';
import { AppBar } from '../app-bar/app-bar.component';
import { Routes } from '../../routes';
import { NavBarBottom } from '../app-bar/nav-bar-bottom.component';
import { NavBarContainer } from '../app-bar/tab-bar.container';

const appBarHeight = '64px';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  content: {
    marginTop: appBarHeight,
    marginBottom: appBarHeight,
  },
});

export interface AppRootProps {
  isLoggedIn: boolean;
}

export const AppRoot = ({ isLoggedIn }: AppRootProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isLoggedIn && <AppBar />}
      <div
        className={clsx({
          [classes.content]: isLoggedIn,
        })}
      >
        <Hidden xsDown>{isLoggedIn && <NavBarContainer />}</Hidden>
        <Hidden smUp>{isLoggedIn && <NavBarBottom />}</Hidden>
        <Routes />
      </div>
      <ReduxToastr timeOut={2000} transitionIn='fadeIn' transitionOut='fadeOut' />
    </div>
  );
};
