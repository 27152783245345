import { connect } from 'react-redux';
// import { LiveSportsPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { LiveSportsPage } from './live-sports.component';

// function mapStateToProps(state: any): ExternalProps {
//   return {
//     landingPages: landingPagesSelector(state),
//   };
// }

// const mapDispatchToProps: ExternalActionProps = {
//   getLandingPagesAction,
// };

export const LiveSportsPageContainer = connect(null, null)(LiveSportsPage);
