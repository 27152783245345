import { ajaxEnded, ajaxStarts } from './axios-busy.helper';

let axios: any;

function setAxios(value: any): void {
  axios = value;
}

function getAxios(): any {
  return axios;
}

function ajaxOK(data: any): any {
  ajaxEnded();
  return data;
}

function ajaxKO(reason: any): any {
  ajaxEnded();
  return Promise.reject(reason);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "axios"
 *
 * @return {object}           The response data
 */
export function axiosJson(url: string, options: any): any {
  ajaxStarts();
  const modifiedOptions = options || {};
  modifiedOptions.url = url;
  return axios
    .request(modifiedOptions)
    .then((response: any) => response.data)
    .then(ajaxOK)
    .catch(ajaxKO);
}

/**
 * Requests a URL to a API, returns a promise.
 * The bearer access token is added to the headers.
 */
export function axiosApi(url: string, options: any): any {
  const allOptions = options || {};

  const { ...modifiedOptions } = allOptions;

  if (modifiedOptions.method) {
    modifiedOptions.method = modifiedOptions.method.toLowerCase();
    if (modifiedOptions.method !== options.method) {
      // eslint-disable-next-line no-console
      console.error('axios: method value must be lowercase');
    }
  } else {
    modifiedOptions.method = 'get';
  }
  if (modifiedOptions.body) {
    modifiedOptions.data = modifiedOptions.body;
    delete modifiedOptions.body;
    console.error("axios: posted data must be in the 'data' property and not in the 'body' property.");
  }

  const headers = modifiedOptions.headers || {};

  modifiedOptions.headers = headers;
  return axiosJson(url, modifiedOptions);
}

export const axiosHelper = {
  axiosApi,
  axiosJson,
  getAxios,
  setAxios,
};
