import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Tab, Tabs } from '@material-ui/core';
import { IRoute, RoutesDictionary, RoutesEnum } from '../../models/routes';
import { getHistory } from '../../utils/history.util';

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: 180,
    color: '#707070',
  },
  appBg: {
    backgroundColor: '#2d2d2d',
  },
  faIcon: {
    fontSize: '1.5rem',
  },
  iconText: {
    fontSize: '.75rem',
    paddingTop: '.5rem',
  },
  appBar: {
    backgroundColor: '#2d2d2d',
  },
}));

export interface NavBarProps {
  pathname: string;
}

export const NavBar = ({ pathname }: NavBarProps) => {
  const classes = useStyles();

  const getTabs = (route: IRoute) => {
    return (
      <Tab
        key={route.name}
        label={route.name}
        icon={<FontAwesomeIcon icon={['fad', route.faIcon]} className={classes.faIcon} />}
        value={route.path}
        classes={{ root: classes.tab }}
      />
    );
  };

  const routes = [
    RoutesDictionary[RoutesEnum.Sportsbook],
    RoutesDictionary[RoutesEnum.LiveSports],
    RoutesDictionary[RoutesEnum.Casino],
    RoutesDictionary[RoutesEnum.Horses],
    RoutesDictionary[RoutesEnum.Promotions],
    RoutesDictionary[RoutesEnum.RAF],
    RoutesDictionary[RoutesEnum.Rules],
  ];

  return (
    <div className={classes.appBar}>
      <Container>
        <Tabs
          value={pathname}
          onChange={(event, newValue: number) => getHistory().push(newValue)}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          aria-label='scrollable force tabs example'
          className={classes.appBg}
          centered
        >
          {routes.map((route: IRoute) => getTabs(route))}
        </Tabs>
      </Container>
    </div>
  );
};
