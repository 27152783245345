import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'userSlice';

export interface UserSliceType {
  isLoading: boolean;
  isLoggedIn: boolean;
  error?: string;
  loggedInUser?: string;
}

export const initialState: UserSliceType = {
  isLoading: false,
  isLoggedIn: false,
  error: undefined,
  loggedInUser: undefined,
};

export const userReducer = createAndMergeSliceReducer(sliceName, initialState);
