import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

// export interface ExternalActionProps {}

// export interface ExternalProps {}

// export const HorsesPage = (props: ExternalActionProps & ExternalProps) => {
export const HorsesPage = () => {
  const classes = useStyles();

  return <div className={clsx(classes.root)}>Horses</div>;
};
