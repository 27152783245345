import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getAppSettings } from '../../../utils/app-settings.util';
import { axiosApi } from '../../../utils/axios/axios.helper';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { userReducer, UserSliceType } from '../user.reducer';

const suffix = `/${userReducer.sliceName}/app`;

const FORGOT_PASSWORD = `FORGOT_PASSWORD${suffix}`;

export const forgotPasswordAction = createAction<string>(FORGOT_PASSWORD);

const FORGOT_PASSWORD_SUCCESS = `FORGOT_PASSWORD_SUCCESS${suffix}`;
const forgotPasswordSuccessAction = createAction(FORGOT_PASSWORD_SUCCESS);

const FORGOT_PASSWORD_ERROR = `FORGOT_PASSWORD_ERROR${suffix}`;
const forgotPasswordErrorAction = createAction<string>(FORGOT_PASSWORD_ERROR);

function* forgotPasswordWatcher(): SagaIterator {
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordWorker);
}
mergeSaga(forgotPasswordWatcher);

function* forgotPasswordWorker(action: any): SagaIterator {
  try {
    console.log(action.payload);
    yield call(forgotPasswordApi, action.payload);
    yield put(forgotPasswordSuccessAction());
  } catch (e) {
    yield put(forgotPasswordErrorAction(e));
  }
}

const forgotPasswordApi = (email: string): Request => {
  const url = `${getAppSettings().baseUrl}/forgotpassword`;
  console.log(url);
  return axiosApi(url, {
    method: 'post',
    headers: { accept: 'json' },
    data: email,
  });
};

const reduceHandlers = {
  [FORGOT_PASSWORD]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
  }),
  [FORGOT_PASSWORD_SUCCESS]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
  [FORGOT_PASSWORD_ERROR]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: false,
  }),
};

userReducer.addActionReducerMap(reduceHandlers);
