import React from 'react';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export interface UserIconPopoverExternalActionProps {
  logoutAction: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    marginTop: '.5rem',
    backgroundColor: theme.palette.secondary.main,
    maxWidth: '375px',
    width: '100%',
    // padding: '1rem',
    flexDirection: 'column',
    overflowX: 'unset',
    overflowY: 'unset',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -15,
      left: '50%',
      width: 15,
      height: 15,
      backgroundColor: '#212222',
      boxShadow: theme.shadows[1],
      transform: 'translate(-50%, 50%) rotate(134deg)',
      clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },

  // Header
  header: {
    backgroundColor: 'black', // 707070
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  headerBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTop: {},
  logoutBtn: {
    float: 'right',
  },

  // Balance Menu
  balanceMenu: {
    paddingBottom: '10px',
    margin: '15px 15px 0',
    borderBottom: '2px solid rgba(255,255,255,.1)',
  },
  totalBalanceBg: {
    padding: '5px 10px',
    marginBottom: '15px',
    backgroundColor: 'rgba(255,255,255,.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  balanceMenuBodyItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  // Footer
  balanceMenuFooter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '15px',
  },
  balanceMenuFooterLinks: {
    display: 'flex',
    padding: '15px 15px 0',
  },
  balanceMenuFooterLinksLeft: {
    display: 'flex',
    padding: '0 5px',
    alignItems: 'center',
    flexDirection: 'column',
    borderRight: '2px solid rgba(255,255,255,.1)',
  },
  balanceMenuFooterLinksRight: {
    display: 'flex',
    padding: '0 5px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  footerLink: {
    margin: '5px 0',
    padding: '5px 10px',
    fontSize: '.9em',
    textDecoration: 'none',
    color: 'rgba(255,255,255,.8)',
  },
  footerAction: {
    width: '90%',
    textAlign: 'center',
  },
}));

export const UserIconPopover = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton color='primary' onClick={handleClick}>
        <FontAwesomeIcon icon={['fad', 'user']} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
      >
        {/* My Account Header */}
        <div className={classes.header}>
          <div className={classes.headerTop}>
            <Typography variant='h6'>VIP2094</Typography>
          </div>
          <div className={classes.headerBottom}>
            <Typography variant='subtitle2'>Jack Spade</Typography>

            <Button variant='outlined' className={classes.logoutBtn} size='small'>
              Log Out
            </Button>
          </div>
        </div>

        {/* Balance Menu Section */}
        <div className={classes.balanceMenu}>
          {/* Total Balance */}
          <div className={classes.totalBalanceBg}>
            <div>
              <Typography variant='h6'>Total Balance</Typography>
            </div>
            <div>
              <Typography variant='h6'>$1069.49</Typography>
            </div>
          </div>

          {/* Cash Balance */}
          <div className={classes.balanceMenuBodyItem}>
            <Typography variant='button'>Cash Balance</Typography>
            <span>$1000.00</span>
          </div>

          {/* Free Play Balance */}
          <div className={classes.balanceMenuBodyItem}>
            <Typography variant='button'>Freeplay</Typography>
            <span>$69.49</span>
          </div>

          {/* Pending Bets */}
          <div className={classes.balanceMenuBodyItem}>
            <Typography variant='button'>Pending Bets</Typography>
            <span>$0.00</span>
          </div>
        </div>

        <div className={classes.balanceMenuFooter}>
          <div className={classes.footerAction}>
            <Button variant='contained' size='large' color='primary'>
              Deposit Now
            </Button>
          </div>

          <div className={classes.balanceMenuFooterLinks}>
            <div className={classes.balanceMenuFooterLinksLeft}>
              <Link href='#' className={classes.footerLink}>
                My Account
              </Link>
              <Link href='#' className={classes.footerLink}>
                Cashier
              </Link>
            </div>
            <div className={classes.balanceMenuFooterLinksRight}>
              <Link href='#' className={classes.footerLink}>
                Refer A Friend
              </Link>
              <Link href='#' className={classes.footerLink}>
                Help
              </Link>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};
