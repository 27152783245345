import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles: any = makeStyles({
  root: {
    padding: 24,
    width: '100%',
  },
});

// export interface ExternalActionProps {}

// export interface ExternalProps {}

// export const LiveSportsPage = (props: ExternalActionProps & ExternalProps) => {
export const LiveSportsPage = () => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Urna duis
      convallis convallis tellus id interdum velit laoreet id. Ipsum suspendisse ultrices gravida dictum fusce. Eget aliquet nibh praesent
      tristique magna sit amet purus. Viverra orci sagittis eu volutpat odio facilisis mauris sit amet. Volutpat maecenas volutpat blandit
      aliquam etiam erat. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Scelerisque felis imperdiet proin fermentum leo.
      Tristique magna sit amet purus gravida quis. Ullamcorper a lacus vestibulum sed arcu. Tempus egestas sed sed risus pretium quam. Erat
      velit scelerisque in dictum. Risus quis varius quam quisque. Ut enim blandit volutpat maecenas volutpat. Semper risus in hendrerit
      gravida rutrum quisque non. Non sodales neque sodales ut etiam sit. Justo eget magna fermentum iaculis. Netus et malesuada fames ac
      turpis. Feugiat scelerisque varius morbi enim nunc faucibus a. Pellentesque adipiscing commodo elit at imperdiet dui. Gravida dictum
      fusce ut placerat orci nulla. Phasellus faucibus scelerisque eleifend donec pretium vulputate. Pharetra sit amet aliquam id. Ut diam
      quam nulla porttitor massa id neque. Morbi tristique senectus et netus et malesuada. Massa eget egestas purus viverra accumsan in.
      Quam quisque id diam vel quam elementum pulvinar. Auctor elit sed vulputate mi. Ut pharetra sit amet aliquam id diam maecenas. Velit
      dignissim sodales ut eu sem integer vitae justo eget. Massa enim nec dui nunc mattis enim ut tellus. Tellus pellentesque eu tincidunt
      tortor aliquam nulla. Etiam tempor orci eu lobortis elementum nibh tellus. Lacus suspendisse faucibus interdum posuere. Fermentum leo
      vel orci porta non. Eget nullam non nisi est sit amet facilisis magna etiam. A scelerisque purus semper eget duis at tellus at. Id
      venenatis a condimentum vitae sapien pellentesque. Lacus vel facilisis volutpat est velit egestas dui id. Proin libero nunc consequat
      interdum varius sit amet. Feugiat sed lectus vestibulum mattis ullamcorper. Proin nibh nisl condimentum id venenatis a condimentum
      vitae. Bibendum at varius vel pharetra vel turpis nunc eget lorem. Eget gravida cum sociis natoque penatibus et magnis dis parturient.
      Ultrices neque ornare aenean euismod elementum nisi. Fusce ut placerat orci nulla pellentesque dignissim. Sem nulla pharetra diam sit
      amet nisl. Egestas dui id ornare arcu odio ut sem nulla pharetra. Lobortis feugiat vivamus at augue eget arcu dictum varius duis.
      Elementum pulvinar etiam non quam lacus. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Nulla
      facilisi etiam dignissim diam quis enim lobortis. Dui sapien eget mi proin sed. Pulvinar pellentesque habitant morbi tristique
      senectus et netus et malesuada. Suscipit tellus mauris a diam maecenas. Viverra nibh cras pulvinar mattis nunc sed blandit. Viverra
      suspendisse potenti nullam ac tortor vitae. Sit amet nisl purus in mollis nunc sed id. Ut placerat orci nulla pellentesque dignissim
      enim. Scelerisque felis imperdiet proin fermentum. Magna fermentum iaculis eu non diam phasellus. Mauris cursus mattis molestie a
      iaculis. Neque egestas congue quisque egestas diam in arcu. Proin fermentum leo vel orci porta. Consectetur lorem donec massa sapien
      faucibus et molestie ac. Ac felis donec et odio pellentesque diam volutpat commodo. Venenatis tellus in metus vulputate eu scelerisque
      felis imperdiet proin. Velit sed ullamcorper morbi tincidunt ornare massa eget egestas. Urna porttitor rhoncus dolor purus non. Augue
      ut lectus arcu bibendum at. Imperdiet dui accumsan sit amet nulla facilisi morbi tempus iaculis. Elementum eu facilisis sed odio morbi
      quis commodo odio. Donec enim diam vulputate ut pharetra sit. Accumsan in nisl nisi scelerisque. Amet mauris commodo quis imperdiet
      massa tincidunt nunc pulvinar sapien. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Nibh cras pulvinar
      mattis nunc sed blandit libero volutpat sed. Pellentesque eu tincidunt tortor aliquam nulla facilisi cras. Pharetra et ultrices neque
      ornare aenean euismod elementum nisi. Amet porttitor eget dolor morbi. Id diam vel quam elementum pulvinar. Sapien pellentesque
      habitant morbi tristique senectus et netus et. Quis risus sed vulputate odio ut. Vulputate ut pharetra sit amet aliquam id diam
      maecenas. Adipiscing commodo elit at imperdiet dui. Rhoncus urna neque viverra justo nec ultrices dui sapien. Eget duis at tellus at
      urna condimentum. Malesuada bibendum arcu vitae elementum curabitur. In tellus integer feugiat scelerisque varius morbi enim nunc.
      Mattis vulputate enim nulla aliquet porttitor. Montes nascetur ridiculus mus mauris vitae ultricies. Sed turpis tincidunt id aliquet
      risus feugiat in. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque. Fusce ut placerat orci nulla
      pellentesque dignissim enim. Fringilla ut morbi tincidunt augue. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae.
      Tellus mauris a diam maecenas sed enim ut. Volutpat commodo sed egestas egestas. Porttitor rhoncus dolor purus non enim praesent
      elementum facilisis leo. Posuere lorem ipsum dolor sit amet consectetur adipiscing.
    </div>
  );
};
