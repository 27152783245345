import { connect } from 'react-redux';
import { isLoggedInSelector } from '../../api/user/user.selector';
import { AppRoot, AppRootProps } from './app-root.component';

function mapStateToProps(state: any): AppRootProps {
  return {
    isLoggedIn: isLoggedInSelector(state),
  };
}

export const AppRootContainer = connect(mapStateToProps, {})(AppRoot);
