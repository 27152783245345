import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography, Button, IconButton, Theme, Link, Divider, Hidden } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserIconPopover } from '../user-icon-popover/user-icon-popover.component';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/top-bg.png` + ')',
    color: 'white',
    '& hr': {
      margin: theme.spacing(0, 2),
    },
  },
  toolbarRightItems: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 64,
    alignItems: 'center',
  },
  shrinkLogo: {
    maxHeight: '50px',
    maxWidth: '50px',
    marginLeft: '1rem',
    color: theme.palette.secondary.main,
  },
}));

export const AppBar = () => {
  const classes = useStyles();

  return (
    <MuiAppBar position='fixed' color='transparent' elevation={3}>
      <Toolbar classes={{ root: classes.toolbar }}>
        <Link href='#/sportsbook'>
          <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
        </Link>

        <div className={classes.toolbarRightItems}>
          <Typography>$69,420</Typography>
          <UserIconPopover />

          <Hidden xsDown>
            <Divider orientation='vertical' flexItem />

            <Button variant='contained' color='secondary' startIcon={<FontAwesomeIcon icon={['fad', 'sack-dollar']} />}>
              <Typography variant='button'>Cashier</Typography>
            </Button>
          </Hidden>

          <Divider orientation='vertical' flexItem />

          <IconButton color='inherit'>
            <FontAwesomeIcon icon={['fad', 'comments']} />
          </IconButton>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};
