import { IconName } from '@fortawesome/fontawesome-common-types';

export interface IRoute {
  name: RoutesEnum;
  path: string;
  faIcon: IconName;
}

export enum RoutesEnum {
  ForgetPassword = 'Forget Password',
  LoginPage = 'Login',
  Dashboard = 'Dashboard',
  Sportsbook = 'Sportsbook',
  LiveSports = 'Live',
  Casino = 'Casino',
  Horses = 'Horses',
  Promotions = 'Promotions',
  RAF = 'Refer-A-Friend',
  Rules = 'Rules',
  Cashier = 'Cashier',
}

export const RoutesDictionary: { [key in RoutesEnum]: IRoute } = {
  [RoutesEnum.ForgetPassword]: {
    name: RoutesEnum.ForgetPassword,
    path: '/forget-password',
    faIcon: 'horse-head',
  },
  [RoutesEnum.LoginPage]: {
    name: RoutesEnum.LoginPage,
    path: '/login',
    faIcon: 'horse-head',
  },
  [RoutesEnum.Dashboard]: { name: RoutesEnum.Dashboard, path: '/', faIcon: 'horse-head' },
  [RoutesEnum.Sportsbook]: {
    name: RoutesEnum.Sportsbook,
    path: '/sportsbook',
    faIcon: 'football-ball',
  },
  [RoutesEnum.LiveSports]: {
    name: RoutesEnum.LiveSports,
    path: '/live-sports',
    faIcon: 'horse-head',
  },
  [RoutesEnum.Casino]: {
    name: RoutesEnum.Casino,
    path: '/casino',
    faIcon: 'dice',
  },
  [RoutesEnum.Horses]: {
    name: RoutesEnum.Horses,
    path: '/horses',
    faIcon: 'horse-head',
  },
  [RoutesEnum.Promotions]: {
    name: RoutesEnum.Promotions,
    path: '/promotions',
    faIcon: 'gift',
  },
  [RoutesEnum.RAF]: {
    name: RoutesEnum.RAF,
    path: '/refer-a-friend',
    faIcon: 'user-friends',
  },
  [RoutesEnum.Rules]: {
    name: RoutesEnum.Rules,
    path: '/rules',
    faIcon: 'question',
  },
  [RoutesEnum.Cashier]: {
    name: RoutesEnum.Cashier,
    path: '/cashier',
    faIcon: 'sack-dollar',
  },
};
