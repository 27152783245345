import React, { useEffect } from 'react';
import { Button, Paper, TextField, Link, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import { RoutesDictionary, RoutesEnum } from '../../../models/routes';
import { ILogin } from '../../../models/login';
import 'clientjs';

// "this library was built to include directly into the browser, not imported."
// @ts-ignore
const client = new ClientJS();

export interface ExternalProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  error?: string;
}
export interface ExternalActionProps {
  loginAction: (login: ILogin) => void;
}

export interface InternalProps {
  classes: any;
}

function LoginPageComp(
  props: InternalProps & ExternalProps & ExternalActionProps,
): React.ReactElement<InternalProps & ExternalProps & ExternalActionProps> {
  const { classes, isLoggedIn, loginAction } = props;
  const [login, setLogin] = React.useState<ILogin>({
    username: '',
    password: '',
    ip: '',
    fingerprint: client.getCustomFingerprint(),
  });

  const setUsername = (username: string) => {
    setLogin({ ...login, username });
  };

  const setPassword = (password: string) => {
    setLogin({ ...login, password });
  };

  const setLoginIp = (ip: string) => {
    setLogin({ ...login, ip });
  };

  const setLoginFp = (fingerprint: number) => {
    setLogin({ ...login, fingerprint });
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => {
        return response.json();
      })
      .then(res => {
        setLoginIp(res.ip);
        // console.log(res.ip);
      })
      .catch(err => console.error('Problem fetching my IP', err));
  }, []);

  if (isLoggedIn) {
    const { path } = RoutesDictionary[RoutesEnum.Dashboard];
    return <Redirect to={path} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.helper}>
        <Paper className={classes.content} elevation={3}>
          <div className={classes.form}>
            <img className={classes.shrinkLogo} alt='logo' src={`${process.env.PUBLIC_URL}/logo.png`} />
            <form
              noValidate
              onSubmit={event => {
                event.preventDefault();
                loginAction(login);
              }}
            >
              <TextField
                id='username'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label='Username'
                value={login.username}
                onChange={event => setUsername(event.target.value)}
                autoFocus
              />
              <TextField
                id='password'
                variant='outlined'
                margin='normal'
                required
                fullWidth
                label='Password'
                type='password'
                value={login.password}
                onChange={event => setPassword(event.target.value)}
              />
              <Link className={classes.moveForgotUsernameToLeft} href={`#${RoutesDictionary[RoutesEnum.ForgetPassword].path}`}>
                Forgot Username
              </Link>
              <Link className={classes.moveForgetPasswordToRight} href={`#${RoutesDictionary[RoutesEnum.ForgetPassword].path}`}>
                Reset Password
              </Link>
              <Button fullWidth variant='contained' color='primary' type='submit'>
                <Typography variant='button'>LogIn</Typography>
              </Button>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}

const styles: any = createStyles({
  shrinkLogo: {
    maxWidth: 100,
    height: 'auto',
    margin: '0px auto',
    display: 'block',
  },
  container: {
    display: 'table',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    backgroundImage: 'url(' + `${process.env.PUBLIC_URL}/login-bg.png` + ')',
    backgroundSize: '1920px 1080px',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'black',
    backgroundPosition: 'top center',
  },
  helper: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  content: {
    margin: '0 auto',
    maxWidth: '350px',
    border: '1px solid #c8a964',
    backgroundColor: '#1a1a1a',
  },
  form: {
    padding: 24,
  },
  moveForgetPasswordToRight: {
    float: 'right',
    display: 'flex',
    paddingBottom: 20,
    color: 'white',
  },
  moveForgotUsernameToLeft: {
    float: 'left',
    display: 'flex',
    paddingBottom: 20,
    color: 'white',
  },
});

export const LoginPage = withStyles(styles)(LoginPageComp);
