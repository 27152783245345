import { createAction } from 'redux-actions';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSliceType, userReducer } from '../user.reducer';
import { IPlayer } from '../../../models/player';
import { mergeSaga } from '../../../utils/redux-saga/merge-saga';
import { vipApi } from '../../../utils/vip-api';
import { AppAction } from '../../../types';
import { ILogin } from '../../../models/login';
import { showToast } from '../../../utils/toast/show-errors';
import { CashierApiResponse } from '../../../models/cashier-response';

const suffix = `/${userReducer.sliceName}/app`;

const LOGIN = `LOGIN${suffix}`;
export const loginAction = createAction<ILogin>(LOGIN);

const LOGIN_ERROR = `LOGIN_ERROR${suffix}`;
const loginErrorAction = createAction<string>(LOGIN_ERROR);
const LOGIN_SUCCESS = `LOGIN_SUCCESS${suffix}`;
const loginSuccessAction = createAction<IPlayer>(LOGIN_SUCCESS);

function* loginWatcher(): SagaIterator {
  yield takeEvery(LOGIN, loginWorker);
}
mergeSaga(loginWatcher);

function* loginWorker(action: any): SagaIterator {
  try {
    const response: CashierApiResponse = yield call(getApiPlayer, action.payload);
    console.log(response.status);

    if (response.status === 200) {
      yield put(loginSuccessAction(action.payload.username));
    } else {
      yield call(showToast, response);
      yield put(loginErrorAction(action.payload.error));
    }
  } catch (e) {
    yield call(showToast, 'Login failed. Please make sure your login information is correct.');
    yield put(loginErrorAction('Login failed. Please make sure your login information is correct.'));
  }
}

const getApiPlayer = (login: ILogin): Request => {
  return vipApi(`/login`, 'post', {
    username: login.username,
    password: login.password,
    ip: login.ip,
    fingerprint: login.fingerprint,
  });
};

const reduceHandlers = {
  [LOGIN]: (slice: UserSliceType): UserSliceType => ({
    ...slice,
    isLoading: true,
    isLoggedIn: false,
  }),
  [LOGIN_SUCCESS]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => {
    return {
      ...slice,
      loggedInUser: action.payload,
      isLoggedIn: true,
      isLoading: false,
    };
  },
  [LOGIN_ERROR]: (slice: UserSliceType, action: AppAction<string, string>): UserSliceType => ({
    ...slice,
    isLoading: false,
    isLoggedIn: false,
    error: action.payload,
  }),
};
userReducer.addActionReducerMap(reduceHandlers);
