import { connect } from 'react-redux';
import { NavBar, NavBarProps } from './tab-bar.component';

function mapStateToProps(state: any): NavBarProps {
  return {
    pathname: state.router.location.pathname,
  };
}

export const NavBarContainer = connect(mapStateToProps, {})(NavBar);
