import { getAppSettings } from './app-settings.util';
import { axiosApi } from './axios/axios.helper';

export function vipApi(path: string, requestType: string, data: any): any {
  const params = new FormData();
  Object.keys(data).forEach(key => {
    const value = data[key];
    params.append(key, value);
  });

  const headers = {
    // 'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  };

  const asiOptions = {
    method: requestType,
    headers,
    data: params,
  };

  const url = getAppSettings().apiUrl + path;

  return axiosApi(url, asiOptions);
}
