import { connect } from 'react-redux';
// import { ReferAFriendPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { ReferAFriendPage } from './raf.component';

// function mapStateToProps(state: any): ExternalProps {
//   return {
//     landingPages: landingPagesSelector(state),
//   };
// }

// const mapDispatchToProps: ExternalActionProps = {
//   getLandingPagesAction,
// };

export const ReferAFriendPageContainer = connect(null, null)(ReferAFriendPage);
