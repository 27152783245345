import { connect } from 'react-redux';
// import { CashierPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { CashierPage } from './cashier.component';

// function mapStateToProps(state: any): ExternalProps {
//   return {
//     landingPages: landingPagesSelector(state),
//   };
// }

// const mapDispatchToProps: ExternalActionProps = {
//   getLandingPagesAction,
// };

export const CashierPageContainer = connect(null, null)(CashierPage);
