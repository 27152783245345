import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFoundPage } from './area/not-found/not-found-page.component';
import { IndexPage } from './area/index-page/index-page.component';
import { LoginPageContainer } from './area/auth/login-page/login-page.container';
import { AdminPrivateRouteContainer } from './shared/private-route/admin-private-route.container';
import { ForgetPasswordPageContainer } from './area/auth/forget-password-page/forgot-password-page.container';
import { SportsBookPageContainer } from './area/sportsbook/sportsbook.container';
import { LiveSportsPageContainer } from './area/live-sports/live-sports.container';
import { CasinoPageContainer } from './area/casino/casino.container';
import { HorsesPageContainer } from './area/horses/horses.container';
import { PromotionsPageContainer } from './area/promotions/promotions.container';
import { ReferAFriendPageContainer } from './area/raf/raf.container';
import { RulesPageContainer } from './area/rules/rules.container';
import { CashierPageContainer } from './area/cashier/cashier.container';

export const Routes = (): React.ReactElement<void> => (
  <Switch>
    <Route exact path='/login' component={LoginPageContainer} />
    <Route exact path='/forget-password' component={ForgetPasswordPageContainer} />

    <AdminPrivateRouteContainer path='/sportsbook' component={SportsBookPageContainer} />
    <AdminPrivateRouteContainer path='/live-sports' component={LiveSportsPageContainer} />
    <AdminPrivateRouteContainer path='/casino' component={CasinoPageContainer} />
    <AdminPrivateRouteContainer path='/horses' component={HorsesPageContainer} />
    <AdminPrivateRouteContainer path='/promotions' component={PromotionsPageContainer} />
    <AdminPrivateRouteContainer path='/refer-a-friend' component={ReferAFriendPageContainer} />
    <AdminPrivateRouteContainer path='/rules' component={RulesPageContainer} />
    <AdminPrivateRouteContainer path='/cashier' component={CashierPageContainer} />

    <AdminPrivateRouteContainer path='/' component={IndexPage} />
    <Route path='' component={NotFoundPage} />
  </Switch>
);
