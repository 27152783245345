import { connect } from 'react-redux';
// import { HorsesPage, ExternalProps, ExternalActionProps } from './sportsbook.component';
import { HorsesPage } from './horses.component';

// function mapStateToProps(state: any): ExternalProps {
//   return {
//     landingPages: landingPagesSelector(state),
//   };
// }

// const mapDispatchToProps: ExternalActionProps = {
//   getLandingPagesAction,
// };

export const HorsesPageContainer = connect(null, null)(HorsesPage);
